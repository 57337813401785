<template>
  <!-- Comments -->
  <b-media :class="{'pb-2':!comment.replies}">
    <template #aside>
      <b-avatar
        src=""
        :text="avatarText(comment.username)"
      />
    </template>
    <h5 class="mt-0">
      {{ comment.username }}
    </h5>
    <p class="font-weight-bolder">
      {{ comment.comment }}
    </p>
    <small>{{ comment.created_at }}</small>
    <h5 class="likes mt-2">
      {{ comment.likes.length }}
      Likes
      <feather-icon
        icon="ThumbsUpIcon"
        size="20"
      />
    </h5>
    <h5
      v-if="comment.replies && comment.replies.length !==0"
      class="mt-2 pt-2"
    >
      {{ comment.replies.length }} Replies
    </h5>

    <b-button
      variant="danger"
      size="sm"
      class="d-block ml-auto"
      @click="deleteComment(comment.id)"
    >
      <feather-icon
        icon="Trash2Icon"
        size="20"
      />
    </b-button>

    <!-- Replies -->
    <slot
      name="replies"
    />
  </b-media>
</template>
<script>
import { avatarText } from '@core/utils/filter'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'Comment',
  props: {
    comment: { type: Object, default: () => {} },
    deleteCommentUrl: { type: String, default: '' },
  },
  data() {
    return {
      deleteMessage: 'Are you sure you want to delete this comment?',
    }
  },
  setup() {
    const { successfulOperationAlert, failedOperationAlert, confirmOperation } = handleAlerts()

    return {
      confirmOperation,
      successfulOperationAlert,
      failedOperationAlert,
    }
  },
  methods: {
    avatarText,
    deleteComment(commentId) {
      this.confirmOperation(this.deleteMessage).then(() => {
        this.$activities.delete(`${this.deleteCommentUrl}${commentId}`).then(() => {
          this.successfulOperationAlert('Comment is deleted successfully')
          this.refreshComments()
        }).catch(() => {
          this.failedOperationAlert('Failed to delete comment')
        })
      })
    },
    refreshComments() {
      this.$emit('deleteComment')
    },
  },
}
</script>
<style lang="scss">

</style>
