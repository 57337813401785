import Vue from 'vue'
import {
  ref,
} from '@vue/composition-api'
import axiosInstances from '@/libs/axios-instances'
import router from '@/router'
import store from '@/store'

export default function getAnnouncements() {
  const announcement = ref({
    mediaRatio: 'landscape',
    media: [],
    entityId: store.getters['mainEntity/getEntityId'],
    order: 0,
    status: false,
    visibility: 'private',
  })
  const loading = ref(false)

  const getAnnouncement = () => {
    loading.value = true
    axiosInstances.activities.get(`/internalops/announcement/${router.currentRoute.params.id}`).then(res => {
      const response = res.data.data
      announcement.value = {
        ...response,
        organization: response.ctaOrganization,
        donationCategory: response.ctaDonationCategory,
        youtubeCover: [response.youtubeCover].filter(cover => cover),
        cta_icon: [response.cta_icon].filter(icon => icon),
        sliderType: response.youtubeLink ? 'video' : 'images',
      }
    }).finally(() => {
      loading.value = false
    })
  }

  const showPopup = status => Vue.swal(
    'Saved Successfully',
    status ? 'Announcement is published Successfully ' : 'Announcement is saved as draft',
    'success',
  )

  return {
    announcement, getAnnouncement, loading, showPopup,
  }
}
