<template>
  <div>
    <spinner-loader :loading="loading" />
    <div v-if="!loading">
      <b-row
        v-if="announcement.youtubeLink"
        align-h="center"
        class="mb-3"
      >
        <iframe
          width="60%"
          height="500"
          :src="`https://www.youtube.com/embed/${getId(announcement.youtubeLink)}`"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </b-row>

      <carousel
        v-else-if="announcement.media"
        :images="announcement.media"
      />

      <b-row>
        <b-col>
          <b-card
            img-top
            img-alt="Blog Detail Pic"
            :title="announcement.title"
          >
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="blog-content"
              v-html="announcement.content"
            />
            <hr>
            <div>
              <small>
                <feather-icon
                  icon="HeartIcon"
                  size="20"
                /> {{ announcement.likesCount }} likes
              </small>
              <small class="ml-1">
                <feather-icon
                  icon="Share2Icon"
                  size="20"
                /> {{ announcement.sharesCount }} share
              </small>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h2 class="mb-2">
            Comments
          </h2>
          <b-card
            v-for="comment in announcement.comments"
            :key="comment.id"
          >
            <comment
              :comment="mapComment(comment)"
              :delete-comment-url="deleteCommentUrl"
              @deleteComment="getAnnouncement"
            >
              <template #replies>
                <div
                  v-for="reply in comment.replies"
                  :key="reply.id"
                >
                  <comment
                    :comment="mapComment(reply)"
                    :delete-comment-url="deleteCommentUrl"
                    class="mt-1"
                    @deleteComment="getAnnouncement"
                  />
                </div>
              </template>
            </comment>
          </b-card>
        </b-col>
      </b-row>
      <pagination
        :per-page="pagination.perPage"
        :total-rows="pagination.totalRows"
        :current-page.sync="pagination.currentPage"
      />
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import Carousel from '@/common/components/Announcements/CustomCarousel.vue'
import Comment from '@/common/components/Activities/Comment.vue'
import announcementsApi from '@/common/compositions/Announcements/announcementsApi'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import paginationData from '@/common/compositions/common/paginationData'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'

export default {
  components: {
    Carousel,
    Comment,
    Pagination,
    SpinnerLoader,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      deleteCommentUrl: `/internalops/announcement/${this.$route.params.id}/comment/`,
    }
  },
  setup() {
    const { announcement, getAnnouncement, loading } = announcementsApi()
    const { pagination } = paginationData()
    return {
      announcement, getAnnouncement, loading, pagination,
    }
  },
  created() {
    this.getAnnouncement()
  },
  methods: {
    mapComment(comment) {
      return {
        username: comment.user?.name,
        comment: comment.comment,
        created_at: comment.created_at,
        id: comment.id,
        likes: comment.likes,
        replies: comment.replies,
      }
    },
    getId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      const match = url.match(regExp)

      return (match && match[2].length === 11)
        ? match[2]
        : null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
